import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import data from '../../data/2nd_live.json';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Section from '../../components/Section';
import SectionTitle from '../../components/SectionTitle';
import LinkBtn from '../../components/LinkBtn';
import ShareButton from '../../components/ShareButton';
import MemberModal from '../../components/MemberModal';
import Slider from '../../components/Slider';

import * as svgPath from '../../images/svgPath';

import {
  middleTitle,
  scheduleDl,
  platform,
  creditList,
  aboutText,
  aboutSec,
  modalAreaStyle,
  listLineup,
  otherSecStyle,
  eventSchedule,
  ticketSchedule,
  buttonTop,
  caution,
  mw600,
  imageMap,
  twitterBlock
} from '../../styles/styles-2nd';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Life Like a Live!2（えるすりー） | イベントアーカイブ"
        description="最先端のバーチャルアイドルONLINEライブフェス『Life Like a Live!』(えるすりー)2020年に開催し4日間で延べ10万人が視聴した伝説のお祭りが帰ってきた！「えるすりー」はオンラインならではの楽しみ方でフェスの臨場感を味わえるイベントです！"
      />
      <section className="mainVisual text-center">
        <StaticImage
          src="../../images/2nd/key_visual.jpg"
          width={1440}
          quality={80}
          alt="Life Like a Live!2（えるすりー）"
          placeholder="none"
          loading="eager"
        />
      </section>

      <Section isWhite={true} addClass={'about_sec'} setCss={aboutSec}>
        <SectionTitle enText={'ABOUT'} jaText={'イベントについて'} textWhite={false} />

        <p className="text-center" css={aboutText}>
          最先端のバーチャルアイドル
          <br className="sp_visible" />
          ONLINEライブフェス
          <br />
          <strong>『Life Like a Live!』(えるすりー)</strong>
          2020年に開催し4日間で延べ10万人が視聴した伝説のお祭りが帰ってきた！
          <br />
          「えるすりー」はオンラインならではの楽しみ方でフェスの臨場感を味わえるイベントです！
          <br />
          今回の舞台は”天空”！？
          <br className="sp_visible" />
          人気バーチャルアイドルたちが
          <br className="pc_visible" />
          特設ステージで最高のパフォーマンスを披露します！
          <br />
          ライブ以外にも一日中楽しめるコンテンツが満載！
          <br />
          <br />
          今回はリアルでも楽しめるコラボカフェやライブビューイング企画、宿泊プランも開催！
          <br />
          みんなで最高の思い出を作りましょう！
        </p>

        <StaticImage
          src="../../images/2nd/tr_logo.png"
          width={365}
          quality={80}
          alt="Life Like a Live!"
          placeholder="none"
          loading="eager"
        />
      </Section>

      <Section addClass={'event_sec'}>
        <SectionTitle enText={'OUTLINE'} jaText={'イベント概要'} textWhite={true} />

        <dl className="c-white" css={scheduleDl}>
          <dt>
            <span>開催日程</span>
          </dt>
          <dd>2021年9月17日(金)～9月20日(月・祝)</dd>
        </dl>

        <div css={platform}>
          <div className="row">
            <div className="col">
              <h3 className="platform-title">
                有料配信
                <br className="sp_visible" />
                プラットフォーム
              </h3>

              <a href="https://www.zan-live.com" target="_blank" rel="noopener noreferrer">
                <div className="image-wrap">
                  <StaticImage
                    src="../../images/z-an_logo.png"
                    width={192}
                    quality={80}
                    alt="Z-an Logo"
                    placeholder="none"
                    loading="eager"
                  />
                </div>
              </a>
            </div>
            <div className="col">
              <h3 className="platform-title">
                無料配信
                <br className="sp_visible" />
                プラットフォーム
              </h3>
              <a
                href="https://www.youtube.com/channel/UC_vFNa_irvWQapwwaasYMOQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="image-wrap">
                  <img className="platform-logo" src={svgPath.youtubeIcon} alt="Youtube Logo" />
                </div>
              </a>
            </div>
          </div>
        </div>

        <h3 className="c-white" css={middleTitle}>
          公演概要
        </h3>
        <div css={[eventSchedule, mw600]}>
          <ul>
            <li>
              <dl>
                <dt>
                  2021年9月18日(土) <br className="sp_visible" />
                  夜公演 開場18:00 開演18:30予定
                </dt>
                <dd>
                  えのぐ / GEMS COMPANY / VALIS / Palette Project / まりなす / もこ田めめめ / LiLYPSE / 叶秘蜜 /
                  ドリームユニット
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  2021年9月19日(日) <br className="sp_visible" />
                  昼公演 開場12:00 開演12:30予定
                </dt>
                <dd>
                  えのぐ / けものフレンズ Vぷろじぇくと / GEMS COMPANY / すたーべあ！ / VALIS / Palette Project /
                  まりなす / もこ田めめめ / 小純マアメ / ドリームユニット
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  2021年9月19日(日) <br className="sp_visible" />
                  夜公演 開場18:00 開演18:30予定
                </dt>
                <dd>
                  朝ノ姉妹 / GEMS COMPANY / Palette Project / VALIS / まりなす / LiLYPSE / 響歌シノ / ドリームユニット
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  2021年9月20日(月・祝) <br className="sp_visible" />
                  昼公演 開場12:00 開演12:30予定
                </dt>
                <dd>
                  AVALON研究生 / 花京院ちえり / GEMS COMPANY / 白雪みしろ / Palette Project / VALIS / 徳井V青空 /
                  南條夢路 / まりなす / 式部めぐり / ドリームユニット
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  2021年9月20日(月・祝) <br className="sp_visible" />
                  夜公演 開場18:00 開演18:30予定
                </dt>
                <dd>
                  えのぐ / 大蔦エル＆キミノミヤ / GEMS COMPANY / Palette Project / VALIS / まりなす / LiLYPSE /
                  くまりすねこねこ / ドリームユニット
                </dd>
              </dl>
            </li>
          </ul>
        </div>
        <div css={mw600}>
          <p css={caution}>
            <small>
              ※GEMS COMPANY、Palette Project、VALIS、まりなす、LiLYPSEは一部のキャストのみの参加となる場合がございます。
            </small>
          </p>
        </div>
      </Section>

      <Section isWhite={true} addClass="ticket_sec">
        <dl css={scheduleDl}>
          <dt>
            <span>販売開始</span>
          </dt>
          <dd>2021年9月1日(水) 12:00〜</dd>
        </dl>

        <h3 css={middleTitle}>チケット</h3>
        <div css={[eventSchedule, mw600, ticketSchedule]}>
          <ul>
            <li>
              <dl>
                <dt>①9月18日(土)夜公演</dt>
                <dd>
                  価格：<strong>5500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>②9月19日(日)昼公演</dt>
                <dd>
                  価格：<strong>5500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>③9月19日(日)夜公演</dt>
                <dd>
                  価格：<strong>5500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>④9月20日(月・祝)昼公演</dt>
                <dd>
                  価格：<strong>5500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>⑤9月20日(月・祝)夜公演</dt>
                <dd>
                  価格：<strong>5500pt</strong>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>⑥9月19日(日)１日通し券</dt>
                <dd>
                  価格：<strong>9900pt</strong>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>⑦9月20日(月・祝)１日通し券</dt>
                <dd>
                  価格：<strong>9900pt</strong>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>⑧全通券</dt>
                <dd>
                  価格：<strong>22000pt</strong>
                </dd>
              </dl>
            </li>
          </ul>
        </div>

        <h3 css={middleTitle}>販売サイト</h3>
        <div css={buttonTop}>
          <LinkBtn linkto="https://www.zan-live.com/live/detail/10099" imageIn={true}>
            販売サイトはこちら
            <div className="image-wrap">
              <StaticImage
                src="../../images/z-an_logo.png"
                height={18}
                quality={80}
                alt="Z-an Logo"
                placeholder="none"
                loading="eager"
              />
            </div>
          </LinkBtn>
        </div>
      </Section>

      <div>
        <div css={imageMap}>
          <StaticImage
            src="../../images/2nd/new_map_fix.jpg"
            width={1440}
            quality={80}
            alt="Event map"
            placeholder="none"
            loading="eager"
          />

          <a href="https://raytrek.net/" target="_blank" rel="noopener noreferrer" className="gareria link-target"></a>

          <a
            href="https://menskireimo.jp/lp201219/?adcode=m_ax_LLLfes_map&argument=LWmskzKs&dmai=m_ax_LLLfes_map"
            target="_blank"
            rel="noopener noreferrer"
            className="kireimo link-target"
          ></a>

          <a
            href="https://www.zan-live.com/live/detail/10099"
            target="_blank"
            rel="noopener noreferrer"
            className="tower link-target"
          ></a>

          <a
            href="https://www.youtube.com/channel/UC_vFNa_irvWQapwwaasYMOQ"
            target="_blank"
            rel="noopener noreferrer"
            className="youtube link-target"
          ></a>

          {/* <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="goods link-target"
          ></a> */}

          <a
            href="https://coestation.jp/consumer/coestationapp/"
            target="_blank"
            rel="noopener noreferrer"
            className="koeste link-target"
          ></a>

          <a
            href="https://www.ak-reservation.com/lllcafe"
            target="_blank"
            rel="noopener noreferrer"
            className="lll_cafe link-target"
          ></a>

          <a
            href="https://t.livepocket.jp/e/lifelikealive2-shabefes"
            target="_blank"
            rel="noopener noreferrer"
            className="shabe_fes link-target"
          ></a>

          <a
            href="https://ctm-ishikawacho.stores.jp/"
            target="_blank"
            rel="noopener noreferrer"
            className="vtrip link-target"
          ></a>

          <a
            href="https://www.showroom-live.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="showroom link-target"
          ></a>

          <a href="https://mixch.tv/" target="_blank" rel="noopener noreferrer" className="mixch link-target"></a>

          <a href="https://reality.app/" target="_blank" rel="noopener noreferrer" className="reality link-target"></a>
        </div>

        <Slider />
      </div>

      <Section addClass="lineup_sec">
        <SectionTitle enText={'LINE UP'} jaText={'ラインナップ'} textWhite={true} />
        <div className="list_lineup" css={listLineup}>
          <div css={modalAreaStyle}>
            <div className="container">
              <ul className="row">
                {data.allMicrocmsLineup.edges
                  .slice(0)
                  .reverse()
                  .map((edge, i) => {
                    const lineup = edge.node;
                    return (
                      <li className="col" key={i}>
                        <MemberModal
                          id={lineup.lineupId}
                          castName={lineup.name}
                          groupName={lineup.group_name}
                          field={lineup.url_field}
                          imageUrl={lineup.image.url}
                        ></MemberModal>
                      </li>
                    );
                  })}
              </ul>

              <div className="other_member text-center">
                <StaticImage
                  src="../../images/2nd/cast_follow_up.jpg"
                  width={600}
                  quality={80}
                  alt="えるすりードリームユニット続々決定"
                  placeholder="none"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section isWhite={true} addClass="other_sec">
        <div css={otherSecStyle}>
          <SectionTitle enText={'OTHER'} jaText={'関連イベント情報'} />
          <div className="other-wrap text-center">
            <a
              className="banner-wrapper"
              href="https://ctm-ishikawacho.stores.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/2nd/l3_tower.jpg"
                width={600}
                quality={80}
                alt="えるすりータワーin横浜/石川町 えるすりータワーリアル宿泊プラン開催!"
                placeholder="none"
                loading="eager"
              />
            </a>
          </div>

          <div className="other-wrap text-center">
            <a
              className="banner-wrapper"
              href="https://bridg-e.jp/projects/lll2tower-project"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../../images/2nd/cf_banner.jpg"
                width={600}
                quality={80}
                alt="みんなで作る！Life Like a Live!2 えるすりータワー建設プロジェクト!"
                placeholder="none"
                loading="eager"
              />
            </a>
          </div>

          <div className="other-wrap text-center">
            <a className="banner-wrapper" href="https://twitter.com/LLL_Cafe" target="_blank" rel="noopener noreferrer">
              <StaticImage
                src="../../images/2nd/cafe_info.jpg"
                width={600}
                quality={80}
                alt="LLL!cafe えるすりーコラボカフェ in IKEMENBOXカフェ(池袋) 2021年9/7〜10月下旬開催!"
                placeholder="none"
                loading="eager"
              />
            </a>
          </div>
        </div>
      </Section>

      <Section addClass="twitter_sec">
        <SectionTitle enText={'TWITTER'} jaText={'ツイッター'} textWhite={true} />

        {/* Twitter Timeline */}
        <div className="text-center" css={twitterBlock}>
          <a
            className="twitter-timeline"
            data-width="500"
            data-height="500"
            href="https://twitter.com/LifeLikeaLive?ref_src=twsrc%5Etfw"
          >
            Tweets by LifeLikeaLive
          </a>
        </div>
        {/* Twitter Timeline */}
      </Section>

      <Section isWhite={true} addClass="credit_sec">
        <SectionTitle enText={'CREDIT'} jaText={'クレジット'} textWhite={false} />

        <dl css={creditList}>
          <dt>主催</dt>
          <dd>バーチャル・エイベックス株式会社 / AVALON</dd>
        </dl>

        <dl css={creditList}>
          <dt>制作委員会</dt>
          <dd>Life Like a Live!制作委員会</dd>
        </dl>

        <ShareButton />
      </Section>
    </Layout>
  );
};

export default IndexPage;
