import React from 'react';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StaticImage } from 'gatsby-plugin-image';

import 'swiper/css';
import 'swiper/css/navigation';

import { css } from '@emotion/react';

export default () => {
  const sliderCss = css`
    @media (min-width: 768px) {
      display: none !important;
    }

    .slider_wrapper {
      position: relative;
      .gatsby-image-wrapper {
        width: 100%;
        div {
          max-width: 100% !important;
          img {
            width: 100%;
          }
        }
      }
    }

    .gareria {
      position: absolute;
      top: 65vw;
      left: 61vw;
      z-index: 2;
      display: inline-block;
      width: 39vw;
      height: 42vw;
    }

    .kireimo {
      position: absolute;
      top: 127.5vw;
      left: 20vw;
      z-index: 2;
      display: inline-block;
      width: 36vw;
      height: 38vw;
    }

    .tower {
      position: absolute;
      top: 21.5vw;
      left: 6.5vw;
      z-index: 1;
      display: inline-block;
      width: 87vw;
      height: 58vw;
    }

    .youtube {
      position: absolute;
      top: 16.5vw;
      left: 11.5vw;
      z-index: 2;
      display: inline-block;
      width: 80vw;
      height: 44vw;
    }

    .goods {
      position: absolute;
      top: 87.5vw;
      left: 59.5vw;
      z-index: 1;
      display: inline-block;
      width: 41vw;
      height: 45vw;
    }

    .koeste {
      position: absolute;
      top: 127vw;
      left: 41vw;
      z-index: 2;
      display: inline-block;
      width: 38vw;
      height: 38vw;
    }

    .lll_cafe {
      position: absolute;
      top: 80vw;
      left: 1vw;
      z-index: 1;
      display: inline-block;
      width: 97vw;
      height: 45vw;
    }

    .shabe_fes {
      position: absolute;
      top: 15vw;
      left: 6.5vw;
      z-index: 1;
      display: inline-block;
      width: 79vw;
      height: 46vw;
    }

    .vtrip {
      position: absolute;
      top: 66vw;
      left: 0vw;
      z-index: 2;
      display: inline-block;
      width: 40vw;
      height: 43vw;
    }

    .showroom {
      position: absolute;
      top: 78vw;
      left: 31.5vw;
      z-index: 2;
      display: inline-block;
      width: 31.5vw;
      height: 20vw;
    }
    .mixch {
      position: absolute;
      top: 78vw;
      left: 1vw;
      z-index: 2;
      display: inline-block;
      width: 30.5vw;
      height: 20vw;
    }
    .reality {
      position: absolute;
      top: 98vw;
      left: 16vw;
      z-index: 2;
      display: inline-block;
      width: 31.5vw;
      height: 21vw;
    }

    .swiper-button-prev {
      left: 10px;
      background: #fff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease;
      &::after {
        content: 'prev';
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0;
        left: -1px;
        position: relative;
      }
    }

    .swiper-button-next {
      right: 10px;
      background: #fff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease;
      &::after {
        content: 'next';
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0;
        right: -1px;
        position: relative;
      }
    }
  `;
  return (
    <div css={sliderCss}>
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide key={1}>
          <div className="slider_wrapper">
            <StaticImage
              src="../../images/2nd/new_map_slide_1.jpg"
              width={640}
              quality={100}
              alt="Life Like a Live!4（えるすりー）"
              placeholder="none"
              loading="eager"
            />

            <a
              href="https://www.youtube.com/channel/UC_vFNa_irvWQapwwaasYMOQ"
              target="_blank"
              rel="noopener noreferrer"
              className="youtube link-target"
            ></a>

            <a
              href="https://lifelikealive.booth.pm/"
              target="_blank"
              rel="noopener noreferrer"
              className="goods link-target"
            ></a>

            <a
              href="https://coestation.jp/consumer/coestationapp/"
              target="_blank"
              rel="noopener noreferrer"
              className="koeste link-target"
            ></a>

            <a
              href="https://www.showroom-live.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="showroom link-target"
            ></a>

            <a href="https://mixch.tv/" target="_blank" rel="noopener noreferrer" className="mixch link-target"></a>

            <a
              href="https://reality.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="reality link-target"
            ></a>
          </div>
        </SwiperSlide>

        <SwiperSlide key={2}>
          <div className="slider_wrapper">
            <StaticImage
              src="../../images/2nd/new_map_slide_2.jpg"
              width={640}
              quality={100}
              alt="Life Like a Live!4（えるすりー）"
              placeholder="none"
              loading="eager"
            />
            <a
              href="https://www.zan-live.com/live/detail/10099"
              target="_blank"
              rel="noopener noreferrer"
              className="tower link-target"
            ></a>
            <a
              href="https://raytrek.net/"
              target="_blank"
              rel="noopener noreferrer"
              className="gareria link-target"
            ></a>
            <a
              href="https://ctm-ishikawacho.stores.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className="vtrip link-target"
            ></a>
          </div>
        </SwiperSlide>

        <SwiperSlide key={3}>
          <div className="slider_wrapper">
            <StaticImage
              src="../../images/2nd/new_map_slide_3.jpg"
              width={640}
              quality={100}
              alt="Life Like a Live!4（えるすりー）"
              placeholder="none"
              loading="eager"
            />
            <a
              href="https://www.ak-reservation.com/lllcafe"
              target="_blank"
              rel="noopener noreferrer"
              className="lll_cafe link-target"
            ></a>
            <a
              href="https://t.livepocket.jp/e/lifelikealive2-shabefes"
              target="_blank"
              rel="noopener noreferrer"
              className="shabe_fes link-target"
            ></a>
            <a
              href="https://menskireimo.jp/lp201219/?m_ax_LLLfes_map&argument=LWmskzKs&dmai=m_ax_LLLfes_map"
              target="_blank"
              rel="noopener noreferrer"
              className="kireimo link-target"
            ></a>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
